<template>
<!--    TODO поставил тут времено стиль, вместо класса, так как класс лучше наверное на какой то общий элемент присвоить.-->
<!--    То есть скорее всего вынести это куда то в блок main-->
    <div style="min-height: 300px" class="container referals p-0">
    <partnersList/>
  </div>
</template>

<script>
  import partnersList from '@/components/app/partners/partners.vue'
  export default {
    components: {partnersList},
  }
</script>
